<template>
    <section class="invoice-add-wrapper p-1">
        <b-row class="invoice-add">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="6" sm="6" xs="12" xl="6" md="6">
                <b-form @submit.prevent>
                    <b-card no-body class="invoice-preview-card">
                        <!-- Header -->
                        <b-card-body class="invoice-padding pb-0">
                            <div class="d-flex align-items-center mt-2">
                                <span
                                    class="title font-weight-bold"
                                    style="width: 20% !important"
                                >
                                    <feather-icon
                                        icon="CalendarIcon"
                                        class=""
                                        size="16"
                                    />
                                    Fecha:
                                </span>
                                <div style="width: 80% !important">
                                    <!-- Select Client -->
                                    <label
                                        class="font-weight-bold"
                                        style="font-size: 18px"
                                        >{{ invoiceData.fechalabel }}</label
                                    >
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-2">
                                <span
                                    class="title font-weight-bold"
                                    style="width: 20% !important"
                                >
                                    <feather-icon
                                        icon="SpeakerIcon"
                                        class=""
                                        size="16"
                                    />
                                    Lector: <span v-if="lector != null">✅</span
                                    ><span v-if="lector == null">⚠️</span>
                                </span>
                                <div
                                    style="width: 80% !important"
                                    v-if="lectores == null"
                                >
                                    <span class="text-danger fw-light">
                                        No se detectó ningun lector de
                                        huellas</span
                                    >
                                </div>
                                <div
                                    style="width: 85% !important"
                                    v-if="lectores != null"
                                >
                                    <b-form-select
                                        v-model="lector"
                                        :options="lectores"
                                        @change="seleccionar_lector($event)"
                                    ></b-form-select>
                                </div>
                            </div>
                        </b-card-body>
                        <!-- Spacer -->
                        <hr class="invoice-spacing" />
						<div class="pb-2 px-3">
							<a
							rel="stylesheet"
							href="https://drive.google.com/file/d/1lh_xvsPjNmUCTiaNzPl5qAiY6rABXcs4/view?usp=drive_link"
							download
							class="small"
						>
							<i class="bi bi-download"></i> Descargar Controlador del
							Lector</a
						>
						</div>
                    </b-card>
                </b-form>
            </b-col>

            <b-col
                cols="12"
                md="4"
                xl="3"
                class="invoice-actions mt-md-0 mt-2 text-center"
            >
                <b-card>
                    <div class="text-center">
                        <img
                            :src="this.huella_dact_img"
                            alt=""
                            height="137px"
                            class="p-1 rounded-circle"
                            id="img_huella"
                            name="img_huella"
                        />
                        <h6 class="text-primary mt-1">
                            Presione su dedo en el lector ...
                        </h6>
                    </div>
                </b-card>
            </b-col>
            <b-col
                cols="12"
                md="4"
                xl="3"
                class="invoice-actions mt-md-0 mt-2 text-center"
            >
                <b-card style="background-color: transparent !important;">
                    <div class="text-center">
                        <img
                            :src="this.logo"
                            alt=""
                            height="189px"
                            class="p-1"
                            id="img_logo"
                            name="img_logo"
                        />
                    </div>
                </b-card>
            </b-col>

            <b-col cols="12">
                <b-card>
                    <b-card-body>
                        <!-- table -->
                        <b-table
                            stacked="md"
                            show-empty
                            small
                            class="position-relative"
                            empty-text="No se encontraron registros"
                            :items="data"
                            :fields="columns"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            @filtered="onFiltered"
                        >
                            <template #cell(folio)="data">
                                <b class=""
                                    >#{{ data.item.folio }}</b
                                >
                            </template>

							<template #cell(sucursal)="data">
                                <b class="text-primary"> 
									{{ data.value.nombre }}</b
                                >
                            </template>

                            <template #cell(fecha)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top">{{
                                        formatDate(data.item.fecha)
                                    }}</span>
                                </div>
                            </template>

                            <template #cell(empleado)="data">
								
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="25"
                                            :text="
                                                avatarText(
                                                    data.value.nombre_completo
                                                )
                                            "
                                            :variant="`light-primary`"
                                            :to="{
                                                /*name: 'apps-users-view', params: { id: data.item.id } */
                                            }"
                                        />
                                    </template>
                                    <span
                                        :to="{
                                            /*name: 'apps-users-view', params: { id: data.item.id }*/
                                        }"
                                        :class="``"
                                        class="font-weight-bold d-block text-nowrap"
                                        >{{ data.value.nombre_completo }}
                                    </span>
                                </b-media>
                            </template>
                        </b-table>
                        <!-- end table -->
                        <!-- Paginacion
                        <div class="">
                            <b-row>
                                <b-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                >
                                    <span class="text-muted">
                                        Mostrando {{ dataMeta.from }} de
                                        {{ dataMeta.to }} de
                                        {{ dataMeta.of }} registros
                                    </span>
                                </b-col>
                                <b-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                >
                                    <b-pagination
                                        class="mb-0 mt-1 mt-sm-0"
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        prev-class="prev-item"
                                        next-class="next-item"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                         Paginacion -->
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BTable,
    BPagination,
    BMedia,
    BFormSelect,
    BAvatar,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import flatpickr1 from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr1.localize(Spanish); // default locale is now Spanish
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "../../apps/invoice/invoiceStoreModule";
import axios from "@axios";
import moment from "moment";

//import { FingerprintReader } from "@digitalpersona/devices";

import huella_img from "../../../assets/images/0.gif";

import logo from "@/assets/images/logo/logo-sushi.png";

const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Mazatlan",
});

export default {
    components: {
        ToastificationContent,
        BFormSelect,
        BRow,
        BPagination,
        BTable,
        BMedia,
        BAvatar,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        flatPickr,
        vSelect,
        Logo,
    },
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    mixins: [heightTransition],
    computed: {
        dataMeta() {
            return {
                from: this.perPage * (this.currentPage - 1) + 1,
                to:
                    this.perPage * this.currentPage < this.totalRows
                        ? this.perPage * this.currentPage
                        : this.totalRows,
                of: this.totalRows,
            };
        },
    },
    async mounted() {
        //delete localstorage
        localStorage.removeItem("token");
        //testing libreria dp
        //let fpreader = new FingerprintReader();
        //testing
        let _instance = this;
        this.sdk.onDeviceConnected = function (e) {
            // Detects if the device is connected for which acquisition started
            //showMessage("Scan Appropriate Finger on the Reader", "success");
            console.log("Dispositivo conectado");
            _instance.lector_status = "Dispositivo conectado";
            _instance.cargarLectores();
        };
        this.sdk.onDeviceDisconnected = function (e) {
            // Detects if device gets disconnected - provides deviceUid of disconnected device
            //showMessage("Device is Disconnected. Please Connect Back");
            console.log("Dispositivo desconectado");
            _instance.lector_status = "Dispositivo desconectado";
            _instance.cargarLectores();
        };
        this.sdk.onCommunicationFailed = function (e) {
            // Detects if there is a failure in communicating with U.R.U web SDK
            //showMessage("Communication Failed. Please Reconnect Device")
            console.log("Communication Failed. Please Reconnect Device");
            _instance.lector_status =
                "Communication Failed. Please Reconnect Device";
        };
        this.sdk.onSamplesAcquired = function (s) {
            // Sample acquired event triggers this function
            //console.warn(s);
            //let img = document.getElementById("img_huella");
            //img.src = "data:image/png;base64," + finger;
            /* console.warn(_instance.ruta);
			if(_instance.ruta == "checador-registro") */
            _instance.verifySample(s);
            //alert(s.samples[0]);
        };
        this.sdk.onQualityReported = function (e) {
            // Quality of sample acquired - Function triggered on every sample acquired
            //document.getElementById("qualityInputBox").value = Fingerprint.QualityCode[(e.quality)];
        };
        await this.cargarLectores();
        await this.cargaMovimientos();
        this.initTrHeight(),
            setInterval(() => {
                this.invoiceData.fecha = moment().format("YYYY-MM-DD HH:mm:ss");
                this.invoiceData.fechalabel = new Date()
                    .toLocaleTimeString("es-MX", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
                    .toUpperCase();
                //this.invoiceData.fechalabel = new moment().locale('es').format('LLL');
            }, 1000);
    },
    created() {
        window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.initTrHeight);
        this.detener_captura();
    },
    data() {
        return {
            ruta: router.currentRoute.name,
            verify_result: null,
            huella_dact_img: null,
            logo: logo,
            currentFormat: Fingerprint.SampleFormat.Intermediate, //Fingerprint.SampleFormat.PngImage,
            sdk: new Fingerprint.WebApi(),
            //sdk_test : this.FingerprintSdkTest(),
            operationToRestart: null,
            acquisitionStarted: false,
            //sdk : null,
            capturando: false,
            lectores: [],
            lector: null,
            data: null,
            columns: [
                { key: "fecha", label: "Fecha", sortable: true },
                { key: "empleado", label: "Empleado", sortable: true },
				{ key: "sucursal", label: "Sucursal", sortable: true },
                { key: "hora_entrada", label: "Hora de Entrada", sortable: true },
                { key: "hora_salida", label: "Hora de Salida", sortable: true },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            pageOptions: [5, 10, 25, 50, 100],
            filter: null,
        };
    },
    methods: {
        seleccionar_lector(event) {
            this.acquisitionStarted = false;
            this.iniciar_captura();
        },
        iniciar_captura() {
            if (this.acquisitionStarted)
                // Monitoring if already started capturing
                return;
            let _instance = this;
            //showMessage("");
            console.log("se inicio captura en el lector" + this.lector);
            this.operationToRestart = this.startCapture;
            this.sdk
                .startAcquisition(
                    Fingerprint.SampleFormat.Intermediate,
                    this.lector
                )
                .then(
                    function () {
                        _instance.acquisitionStarted = true;

                        //Disabling start once started
                        //disableEnableStartStop();
                    },
                    function (error) {
                        //showMessage(error.message);
                        console.log(error.message);
                    }
                );
        },
        detener_captura() {
            if (!this.acquisitionStarted)
                //Monitor if already stopped capturing
                return;
            let _instance = this;
            //showMessage("");
            console.log("se detuvo captura en el lector " + this.lector);
            this.sdk.stopAcquisition().then(
                function () {
                    _instance.acquisitionStarted = false;

                    //Disabling stop once stopped
                    //disableEnableStartStop();
                },
                function (error) {
                    //showMessage(error.message);
                    alert(error.message);
                }
            );
        },
        verifySample(sample) {
            let samples = JSON.parse(sample.samples);
            console.log(samples[0]);
            //let finger = Fingerprint.b64UrlTo64(samples[0]);
            //this.huella_dact_img = "data:image/png;base64," + finger;
            //call axios service
            this.invoiceData.huella = samples[0].Data;

            axios
                .post("/api/checador/verify", this.invoiceData)
                .then((response) => {
                    this.verify_result = response.data.data;
                    //if response status is 201 then checada is not registered
                    if (response.status == 201) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                variant: "success",
                                title: "Huella identificada correctamente",
                                text: response.data.data.nombre_completo,
                                icon: "CheckSquareIcon",
                            },
                        });
                        //guardar el registro de la checada
                        let payload = {
                            empleado_id: response.data.data.id,
                            fecha: this.invoiceData.fecha,
                            sucursal_id: 1,
                            tipo: "lector",
                        };

                        axios
                            .post(
                                "/api/movimientos/checar-entrada-general",
                                payload
                            )
                            .then((response) => {
                                //if response status is 201 then checada is not registered
                                if (response.status == 201) {
                                    this.cargaMovimientos();
                                } else if (response.status == 202) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            variant: "danger",
                                            title: "No se pudo registrar entrada o salida",
                                            text: response.data,
                                            icon: "ALertTriangleIcon",
                                        },
                                        timeout: 5000,
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        variant: "danger",
                                        title: "Alerta",
                                        text: error.error,
                                        icon: "ALertTriangleIcon",
                                    },
                                });
                            });
                    } else if (response.status == 203) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                variant: "danger",
                                title: response.data.data,
                                text: "No se encontró la huella capturada en el sistema",
                                icon: "SlashIcon",
                            },
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.status === 422) {
                        //console.log(err.response.data.errors);
                        //console.log(err.response.data.errors.email[0]);
                        //console.log(err.response.data.errors.password[0]);
                        //console.log(err.response.da
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                variant: "danger",
                                title: "Sucursal no seleccionada",
                                text: "Por favor seleccione una sucursal para registrar la entrada o salida del empleado",
                                icon: "ALertTriangleIcon",
                            },
                        });
                    }
                });
        },
        readyForIdentify() {
            return this.lector !== null;
        },
        async cargarLectores() {
            this.lectores = [];
            let rd = this.sdk.enumerateDevices();
            rd.then((readers) => {
                if (readers.length > 0) {
                    //this.lectores = availableReaders;
                    readers.forEach((reader) => {
                        this.lectores.push(reader);
                    });
                    this.lector = this.lectores[0];
                    //this.myReader.reader.startCapture();
                    this.iniciar_captura();
                    this.huella_dact_img = huella_img;
                } else {
                    //alert("Please Connect the Fingerprint Reader")
                    this.lector = null;
                }
            }).catch((error) => {
                console.log(error);
                this.lector = null;
            });
        },
        async cargaMovimientos() {
            const r = await axios.get(
                `/api/movimientoschecador?q=${moment().format(
                    "YYYY-MM-DD"
                )}&ct=0`
            );
            this.data = r.data.data.data ? r.data.data.data : [];
            this.totalRows = this.data.length;
            //get only the rows of the current date
            /* this.data = this.data.filter(
                (item) => {
                    return moment(item.fecha).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
                }
            ); */
        },
        onFiltered(filteredItems) {
            this.currentPage = 1;
            this.totalRows = filteredItems.length;
        },
        formatDate(date) {
            if (!date) return null;
            return moment(date).format("DD/MM/YYYY");
        },
        formatMoney(value) {
            //format to mexican currency
            var formatter = new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 2,
            });

            return formatter.format(value);
        },

        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                //this.trSetHeight(this.$refs.form.scrollHeight)
            });
        },
    },
    setup() {
        const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
            store.registerModule(
                INVOICE_APP_STORE_MODULE_NAME,
                invoiceStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
                store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
        });

        /*const clients = ref([])
	  store.dispatch('app-invoice/fetchClients')
		.then(response => { clients.value = response.data })*/

        const itemFormBlankItem = {
            empleado: null,
            horas_trabajadas: null,
            pago: 0,
            observacion: "",
        };

        const invoiceData = ref({
            id: null,
            fecha: new Date(),
            centro_costo_padre_id: 0,
            centro_costo_id: 0,

            // ? Set single Item in form for adding data
            items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
            observaciones: "",
        });

        const updateItemForm = (index, val) => {
            const { cost, qty, description } = val;
            invoiceData.value.items[index].cost = cost;
            invoiceData.value.items[index].qty = qty;
            invoiceData.value.items[index].description = description;
        };

        const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

        return {
            invoiceData,
            //clients,
            //itemsOptions,
            updateItemForm,
            itemFormBlankItem,
            paymentMethods,
            avatarText,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
    .add-new-client-header {
        padding: $options-padding-y $options-padding-x;
        color: $success;

        &:hover {
            background-color: rgba($success, 0.12);
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
    background-color: $product-details-bg;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}
</style>
